import styled from 'styled-components'

import Cards from 'components/Card/Cards'

export default styled(Cards)`
  padding-bottom: 40px;
  &::before {
    top: 58%;
  }
`
