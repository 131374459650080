import styled from 'styled-components'

import media from 'styles'

import H5 from 'components/H5'

import BackgroundPrimary from 'images/benefit/background_primary.png'
import BackgroundSecondary from 'images/benefit/background_secondary.png'

export const Title = styled(H5)`
  position: relative;
  font-weight: 600;
  font-size: 24px;
  padding: 25px 0;
  margin: 0;

  &::before {
    content: '';
    position: absolute;
    top: -8px;
    left: -25px;
    width: 103px;
    height: 97px;
    background: url() no-repeat center center;
    z-index: -1;

    ${ media.lessThan('xl')`
      left: calc(50% - 52px);
    ` }
  }
`

export const Wrapper = styled.section`
  font-size: 14px;
  text-align: left;

  ${ media.lessThan('xl')`
    text-align: center;
  ` }

  &:nth-child(odd) {
    ${ Title } {
      color: #ff8d00;

      &::before {
        background-image: url(${ BackgroundSecondary });
      }
    }
  }

  &:nth-child(even) {
    ${ Title } {
      &::before {
        background-image: url(${ BackgroundPrimary });
      }
    }
  }

  &:nth-child(2) ${ Title }::before {
    transform: rotate(-60deg);
  }

  &:nth-child(3) ${ Title }::before {
    transform: rotate(130deg);
  }

  &:nth-child(4) ${ Title }::before {
    transform: rotate(20deg);
  }

  &:nth-child(5) ${ Title }::before {
    transform: rotate(70deg);
  }

  &:nth-child(6) ${ Title }::before {
    transform: rotate(40deg);
  }
`
