import styled from 'styled-components'
import { Container } from 'reactstrap'

import media from 'styles'

import Background from 'images/universal_particles.png'

export default styled(Container).attrs({ fluid: true })`
  position: relative;
  padding: 0;
  margin-top: 180px;
  margin-bottom: 20px;
  font-size: 15px;
  color: #3f3f3f;

  ${ media.greaterThan('xl')`
    margin-bottom: 180px;
  ` }

  > .container:first-of-type {
    ${ media.greaterThan('xl')`
      padding-bottom: 190px;
  ` }
  }

  ${ media.greaterThan('lg')`
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0%;
      background: url(${ Background }) no-repeat left top;
    }
  ` }
`

export const Mockup = styled.div`
  ${ media.greaterThan('xl')`
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(25%);
  ` }

  width: 80%;

  ${ media.lessThan('xl')`
    width: 50%;
    margin: 0 auto 30px auto;
  ` }
`

export const Addon = styled.div`
  position: relative;
  background-color: #fff;
  padding: 15px;
  border-radius: 20px;
  margin-bottom: 15px;
  box-shadow: 3px 7px 24px #e4e4e4;

  ${ media.greaterThan('lg')`
    padding: 0 0 100% 0;
    border-radius: 50%;
    margin: 0;

    &::before, &::after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }

    &::before {
      background: #008172;
    }

    &::after {
      background: #ff8d00;
    }
  ` }

  > div {
    ${ media.greaterThan('lg')`
      position: absolute;
      display: flex;
      left: 40px;
      right: 40px;
      top: 0;
      bottom: 0;
    ` }

    font-size: 14px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`

export const Addons = styled.div`
  .row > div {
    &:nth-child(1) ${ Addon } {
      &::before {
        top: 2%;
        right: 25%;
      }

      &::after {
        bottom: 2%;
        left: 25%;
      }
    }

    &:nth-child(2) ${ Addon } {
      &::before {
        top: -1%;
        left: 32%;
      }

      &::after {
        bottom: -1%;
        right: 32%;
      }
    }

    &:nth-child(3) ${ Addon } {
      &::before {
        top: -2%;
        right: 39%;
      }

      &::after {
        bottom: 14%;
        right: 9%;
      }
    }
  }
`
