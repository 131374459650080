import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import { Container, Row } from 'reactstrap'
import Img from 'gatsby-image'

import * as Routes from 'routes'
import Layout from 'layouts/static'

import Main from 'components/Main'
import * as CompanyMark from 'components/CompanyMark'
import ButtonLink from 'components/Button/Link'
import Heading from 'components/Heading'
import H2 from 'components/H2'
import H3 from 'components/H3'
import H4 from 'components/H4'
import H5 from 'components/H5'
import * as Card from 'components/Card'
import CardLink from 'components/Card/Link'
import Services from 'components/Services'
import Benefits, { List as BenefitsList } from 'components/Benefits'
import * as Benefit from 'components/Benefits/Benefit'
import Steps from 'components/Steps'
import ContactWidget from 'components/ContactWidget'

import ProjectFlow, {
  Mockup as ProjectFlowMockup,
  Addons,
  Addon
} from 'views/websites/ProjectFlow'

const WebsitesPage = ({ data }) => (
  <Layout header={header}>
    <Helmet title="Projektowanie stron www">
      <meta
        name="description"
        content="Projektowanie stron www - od nowoczesnych one page’y, przez strony firmowe średniej wielkości, aż po rozbudowane giganty pełne efektownego designu. Wszystkie atrakcyjne, responsywne i wykończone pod klucz."
      />
    </Helmet>

    <Main>
      <Heading id="krok-1">
        <H4>
          <CompanyMark.Left /> Krok 1 <CompanyMark.Right />
        </H4>
        <H2 className="text-xl-left">Czego potrzebujesz?</H2>
      </Heading>

      <Services>
        <Container>
          <Row>
            <div className="col-12 col-md px-4 mb-4 mb-lg-0">
              <Link to={Routes.CONTACT}>
                <Card.Wrapper noIcon>
                  <Card.Icon className="mx-auto">
                    <H3>Strony www</H3>
                  </Card.Icon>
                  <div className="mt-4">
                    Tworzymy strony www wszelkiej maści - od nowoczesnych one
                    page’y, przez strony firmowe średniej wielkości, aż po
                    rozbudowane giganty pełne efektownego designu. Wszystkie
                    atrakcyjne, responsywne i wykończone pod klucz.
                  </div>
                  <CardLink>
                    Dowiedz się więcej <span>&gt;</span>
                  </CardLink>
                </Card.Wrapper>
              </Link>
            </div>
            <div className="col-12 col-md px-4 mb-4 mb-lg-0">
              <Link to={Routes.CONTACT}>
                <Card.Wrapper noIcon>
                  <Card.Icon className="mx-auto">
                    <H3>Sklepy internetowe</H3>
                  </Card.Icon>
                  <div className="mt-4">
                    E-commerce to Twoja bajka? Stworzymy dla Ciebie platformę,
                    która nie tylko wygląda genialnie, ale i sprzyja sprzedaży.
                    Szeroki wachlarz metod płatności, wysyłki, promocje,
                    wishlisty, rozwiązania ułatwiające ścieżkę zakupową? To
                    dopiero początek.
                  </div>
                  <CardLink>
                    Dowiedz się więcej <span>&gt;</span>
                  </CardLink>
                </Card.Wrapper>
              </Link>
            </div>
            <div className="col-12 col-md px-4">
              <Link to={Routes.CONTACT}>
                <Card.Wrapper noIcon>
                  <Card.Icon className="mx-auto">
                    <H3>Pozostałe</H3>
                  </Card.Icon>
                  <div className="mt-4">
                    Portale, blogi, CRMy, integracje, streaming, zarządzanie
                    firmą online – dla nas ograniczeń nie ma. Napisz nam, jakie
                    masz potrzeby, a my sprawimy, że Twój pomysł wejdzie w życie
                    z pełnym impetem.
                  </div>
                  <CardLink>
                    Dowiedz się więcej <span>&gt;</span>
                  </CardLink>
                </Card.Wrapper>
              </Link>
            </div>
          </Row>
        </Container>
      </Services>

      <Benefits>
        <Heading secondary noBackground>
          <H4>
            <CompanyMark.Left /> Krok 2 <CompanyMark.Right />
          </H4>
          <H2>Dlaczego my?</H2>
          <Container>
            <Row>
              <div className="col-12 col-md-10 mx-auto my-5 text-center">
                W cenie masz wszystko - kompleksowość to dla nas nie pusty
                slogan. Dzięki naszej ofercie nie musisz łączyć wielu
                podwykonawców, robić list to-do i zastanawiać się, czego
                potrzebujesz, żeby przeskoczyć oczekiwania - po prostu my
                przeskoczymy Twoje.
              </div>
            </Row>
            <BenefitsList>
              <Benefit.Wrapper>
                <Benefit.Title>TEN design</Benefit.Title>
                Jesteśmy na bieżąco z trendami, a przed rozpoczęciem prac
                przeglądamy Twoją branżę i konkurencję. Proponujemy wygląd
                strony, który wyróżni Cię na korzyść. Tu nie ma miejsca na
                kompromisy - mówimy przecież o Twoim biznesie.
              </Benefit.Wrapper>
              <Benefit.Wrapper>
                <Benefit.Title>USER experience</Benefit.Title>
                Sama przepięknie wyglądająca strona może okazać się
                niewystarczająca w dzisiejszych czasach. Dlatego w pełni zadbamy
                o jej użyteczność i łatwość użytkowania. Pozwoli to użytkownikom
                na spokojną kontemplację Twojej oferty.
              </Benefit.Wrapper>
              <Benefit.Wrapper>
                <Benefit.Title>NOWOCZESNE rozwiązania</Benefit.Title>
                Synchronizacja danych live, podział ruchu, animacje, przejścia,
                formularze, mapy Google, slidery, responsywność? To dopiero
                początek. Dla nas nie ma rzeczy niemożliwych. Nasi programiści
                doskonale wiedzą, że każdy projekt jest indywidualny.
              </Benefit.Wrapper>
              <Benefit.Wrapper>
                <Benefit.Title>SEO friendly</Benefit.Title>
                Pozycjonowanie strony to jeden z największych kluczy do sukcesu
                biznesu. Każda utworzona przez nas strona posiada metkę
                "Stworzone dla SEO”. W praktyce oznacza to, że Twoja witryna
                będzie w pełni przyjazna dla wyszukiwarek (m.in. Google).
                Przełoży się to bezpośrednio na wzrost popularności i ilości
                odwiedzających.
              </Benefit.Wrapper>
              <Benefit.Wrapper>
                <Benefit.Title>NEWSlettery i news feedy</Benefit.Title>
                Wykonane przez nas strony podłączamy do Twoich platform
                mailingowych, social mediów, chatów czy CRMów. Nie zostawimy Cię
                w połowie kroku - oddajemy Ci stronę w stanie deweloperskim, po
                wizycie dekoratorów wnętrz.
              </Benefit.Wrapper>
              <Benefit.Wrapper>
                <Benefit.Title>ZABEZPIECZENIA</Benefit.Title>
                Zdajemy sobie sprawę, że wyciek krytycznych danych lub
                niedostępność w sieci negatywnie wpływa na opinie o biznesie.
                Pisany przez nas kod jest dokładnie analizowany i chroniony
                przed atakiem z zewnątrz, a nasze nowatorskie rozwiązania
                pozwalają w większości przypadków całkowicie zabezpieczyć się
                przed atakami przeciążającymi DDoS.
              </Benefit.Wrapper>
            </BenefitsList>
          </Container>
        </Heading>
      </Benefits>

      <ProjectFlow>
        <Container>
          <Row>
            <div className="col-12 col-xl-5">
              <Heading left noBackground>
                <H4 className="text-center text-xl-left">
                  <CompanyMark.Left /> Krok 3 <CompanyMark.Right />
                </H4>
                <H2 className="text-center text-xl-left">Jak to robimy?</H2>
                <div className="mt-5 text-center text-xl-left">
                  Terminowo, kompleksowo i na czasie, dzięki doskonałej
                  znajomości branży i wymagań dzisiejszego biznesu. Stawiając na
                  relacje, bo z wzajemnością lubimy ludzi. Zawsze dajemy z
                  siebie wszystko i więcej!
                </div>
              </Heading>
            </div>
            <div className="col-12 col-xl align-self-end">
              <ProjectFlowMockup>
                <Img
                  fluid={data.projectFlowMockup.childImageSharp.fluid}
                  alt="Przebieg projektu"
                />
              </ProjectFlowMockup>
            </div>
          </Row>
        </Container>
        <Container>
          <Row>
            <Addons className="col-11 mx-auto">
              <Row>
                <div className="col-12 col-lg-5 mr-auto col-xl-4">
                  <Addon>
                    <div>
                      <H5>Hosting, domena i integracje</H5>
                      <br />
                      Posiadasz już hosting i domenę? Chętnie przeniesiemy
                      wszystko na Twój serwer i podłączymy tak, że nie będziesz
                      musiał robić już nic więcej. Jeśli nie, to chętnie
                      dobierzemy najoptymalnejsze i sprawdzone przez nas oferty.
                    </div>
                  </Addon>
                </div>
                <div className="col-12 col-lg-5 mr-auto col-xl-4">
                  <Addon>
                    <div>
                      <H5>Łatwy w obsłudze CMS + szkolenie</H5>
                      <br />
                      Nasze strony są łatwe w obsłudze, ponieważ chcemy, żebyś
                      po wszystkim był samodzielny i nie musiał szukać
                      programisty do każdej drobnostki. Jeśli zapragniesz,
                      udzielimy Ci krótkiego szkolenia, a to wszystko całkowicie
                      za darmo.
                    </div>
                  </Addon>
                </div>
                <div className="col-12 col-lg-5 mx-auto col-xl-4">
                  <Addon>
                    <div>
                      <H5>Wsparcie od naszej firmy</H5>
                      <br />
                      Po zakończonej pracy nie zostawiamy Cię z niczym. Masz
                      gwarancję na jakość naszych stron, więc jeśli w
                      międzyczasie coś przestanie grać, zespół programistów od
                      razu przeanalizuje i rozwiąże problem. Możesz na nas
                      liczyć.
                    </div>
                  </Addon>
                </div>
              </Row>
            </Addons>
          </Row>
        </Container>
      </ProjectFlow>

      <Steps secondary>
        <Container>
          <Row>
            <Heading noBackground light>
              <H4>
                <CompanyMark.Left /> Współpraca z nami <CompanyMark.Right />
              </H4>
              <H2>Kogo werbujemy do pomocy?</H2>
            </Heading>
          </Row>
          <Row>
            <div className="col-12 col-md-11 mx-auto mt-5 pt-3">
              Stale współpracujemy z firmami, których usługi uzupełniają nasze i
              z którymi wspólnie tworzymy nową jakość dla naszych klientów. Nie
              musisz szukać do swojej strony pośredników płatności online,
              grafika do logotypu, agencji SEO czy copywritera do nowych
              tekstów. W trakcie współpracy na pewno pomożemy dobrać Ci
              zewnętrznych usługodawców, jednocześnibe uzyskując dla Ciebie dużo
              korzystniejsze warunki ofert.
            </div>
          </Row>
        </Container>
      </Steps>

      <ContactWidget
        title="Zarezerwuj nową stronę www"
        subtitle="Porozmawiaj z nami"
      />
    </Main>
  </Layout>
)

export const query = graphql`
  query {
    projectFlowMockup: file(
      relativePath: { eq: "page_websites/projectflow_mockup.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 601, maxHeight: 484) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const header = {
  title: (
    <Fragment>
      Twoja strona ulubionym
      <br />
      miejscem w sieci?
      <br />
      Twoja duma<span>.</span> Nasz cel<span>.</span>
    </Fragment>
  ),
  subtitle: <Fragment>Projektowanie stron www</Fragment>,
  content: (
    <div>
      <div className="my-5">
        <CompanyMark.Left /> Zaczynajmy! <CompanyMark.Right /> Czyli proces
        tworzenia strony
      </div>
      <ButtonLink
        to="#"
        variant="primary"
        onClick={e => {
          e.preventDefault()

          document
            .getElementById('krok-1')
            .scrollIntoView({ behavior: 'smooth' })
        }}
      >
        Dowiedz się więcej
      </ButtonLink>
    </div>
  ),
  image: 'websites'
}

export default WebsitesPage
