import styled from 'styled-components'
import { Container } from 'reactstrap'

import media from 'styles'

import Background from 'images/benefit/benefits_particles.png'

export default styled(Container).attrs({ fluid: true })`
  position: relative;
  padding: 0;
  font-size: 15px;
  color: #3f3f3f;

  ${ media.greaterThan('lg')`
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 65%;
      left: 0;
      background: url(${ Background }) no-repeat left top;
    }
  ` }
`

export const List = styled.ul`
  width: 100%;
  display: grid;

  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 40px;

  ${ media.lessThan('sm')`
    padding: 0 15px;
  ` }

  ${ media.greaterThan('xl')`
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 110px;
  ` }
`
